import React from 'react';
import { CreatorServicesFormStyled } from './styled';
import { Field, reduxForm } from 'redux-form';
// import { iconImages } from 'assets'
import { VALIDATE } from 'helpers';
import {
  Input,
  ButtonCustom,
  InputCheckbox,
  InputCheckBoxMulti,
  InputUploadFIle,
} from 'components';

class CreatorServicesForm extends React.Component {
  state = {};

  render() {
    const { handdleSave, handdleDelete, isAdd, catogoriesOptions, initialValues } = this.props;

    return (
      <CreatorServicesFormStyled>
        <div className="grop_form">
          <div className="box_label">
            <div className="label">Services Name</div>
            <div className="input_layer">
              <Field
                theme_standard_2
                name="name"
                placeholder="Enter Services Name"
                component={Input}
                validate={VALIDATE.REQUIRE}
              />
            </div>
          </div>
          <div className="box_label">
            <div className="label">Rate Coins</div>
            <div className="input_layer">
              <Field
                theme_standard_2
                name="rate_coins"
                placeholder="Enter Rate"
                component={Input}
                validate={VALIDATE.NUMBER}
              />
            </div>
          </div>
          <div className="box_label">
            <div className="label">Category</div>
            <div className="input_layer">
              <Field
                theme_standard
                name="categories"
                options={catogoriesOptions}
                component={InputCheckBoxMulti}
              />
            </div>
          </div>
          <div className="box_label">
            <div className="label">Option</div>
            <div className="item_checkout">
              <Field
                theme_standard
                name="is_highlight"
                label="Highlight"
                component={InputCheckbox}
              />
            </div>
          </div>
          <div className="box_label">
            <div className="label">Photo</div>
            <div className="input_layer">
              <Field
                theme_standard
                name="photo_file"
                accept="image/x-png,image/gif,image/jpeg"
                buttonLabel="Picture"
                component={InputUploadFIle}
                width="100"
                title="Photo"
                haveFilePhoto={initialValues && initialValues.photo_file ? true : false}
                onChange={this.uploadFile}
              />
            </div>
          </div>
          <div className="box_label">
            <div className="label">Video</div>
            <div className="input_layer">
              <Field
                theme_standard
                name="video_file"
                accept="video/mp4,video/x-m4v,video/*"
                buttonLabel="Video"
                component={InputUploadFIle}
                title="Video"
                width="100"
                haveFileVideo={initialValues && initialValues.video_file ? true : false}
                onChange={this.uploadFile}
              />
            </div>
          </div>
          <div className="button_layer">
            <ButtonCustom theme_yellow_2 label="SAVE" onClick={handdleSave} />
            {!isAdd && (
              <ButtonCustom
                theme_yellow_2
                label="DELETE"
                onClick={handdleDelete}
              />
            )}
          </div>
        </div>
      </CreatorServicesFormStyled>
    );
  }
}

export default reduxForm({
  form: 'CreatorServicesForm', // a unique identifier for this form
  enableReinitialize: true,
})(CreatorServicesForm);
