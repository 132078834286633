import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const InputSuggestCreator2Styled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  display: flex;
  width: 100%;
  .react-autosuggest__container {
    position: relative;
  }
  .react-autosuggest__input {
    width: calc(100% - 42px) ;
    font-size: ${FONT.SIZE_5};
    font-family: unicaOne_regular;
    padding: 10px 20px;
    ::placeholder {
      color: ${COLOR.GREY_2};
      font-family: unicaOne_regular;
    }
  }
  .react-autosuggest__input--focused {
    outline: none;
  }
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .react-autosuggest__suggestions-container {
    display: none;
  }
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 43px;
    width: 50%;
    border: 1px solid ${COLOR.GREY_2};
    background-color: ${COLOR.WHITE_1};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    height: 200px;
    overflow-y: scroll;
  }
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 10px;
    display: flex;
    justify-content: flex-start;
  }
  .react-autosuggest__suggestion--highlighted {
    // background-color: ${COLOR.GREY_1};
  }

  .input_layout {
    width: 100%;
  }
  .button {
    cursor: pointer;
    font-family: unicaOne_regular;
    font-size: ${FONT.SIZE_5};
    color: ${COLOR.YELLOW_2};
    padding: 10px 30px;
    border: 1px solid ${COLOR.GREY_2};
    font-weight: 600;
    border-radius: 5px;
    background: ${COLOR.BLUE_5};
    &.disabled {
      background: ${COLOR.GREY_2};
      color: ${COLOR.BLUE_5};
      cursor: not-allowed;
    }
  }
  .error_wrap {
    color: ${COLOR.RED_1};
    font-size: ${FONT.SIZE_1};
  }
  @media(max-width: ${SCREEN.PHONE}){
    font-size: ${FONT.SIZE_2};
    .react-autosuggest__suggestions-container--open {
      width: 100%;
    }
  }
`;
