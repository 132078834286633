import styled from "styled-components"
import { 
    // COLOR,
    // FONT
 } from 'assets'
 
export const InputDropdownStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .theme_standard {
     
    }
    .theme_full {
        .select_box {
            width: 100%;
        }
    }
`