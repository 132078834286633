import styled from "styled-components"
// import { 
//     COLOR,
//     FONT
//  } from 'assets'
 
export const CreatorServiceFormStyled = styled.div`
/*===============================================
 Container 
===============================================*/

/*===============================================
Theme 
===============================================*/
     .title {
          margin-bottom: 10px;
          font-weight: bold;
     }
   .btn_layout {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
       .btn_add {
            border-radius: 4px;
            padding: 6px 20px;
            font-size: 16px;
            background: #ffd11a;
            border: 1px;
            solid #d0d3cd;
            margin: 5px;
       }
       .btn_cancel {
            border-radius: 4px;
            padding: 6px 20px;
            font-size: 16px;
            background: gray;
            border: 1px;
            solid #d0d3cd;
            margin: 5px;
       }
   }
`