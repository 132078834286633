import React from "react";
import { WheelCreatorSection } from "./styled";
import AgencyFormCreator from "../WheelFormCreator";
import { ButtonCustom } from "components";

const WheelCreator = ({
  showCreatorData,
  creatorData,
  onDelete,
  wheelSlug,
  handleLoadWheel,
  fetchCreatorService,
}) => {
  return (
    showCreatorData && (
      <WheelCreatorSection>
        <div className="wheel-title">Creator</div>
        <div className="cretor-container">
          {creatorData.map((value, index) => (
            <div key={index.toString()} className="creator-list">
              <span className="creator-name">
              {value.displayname}
              {
                value.service && 
                <span className='show_service'>
                  {` - ${value.service.name}`}
                </span>
              }
              </span>
              <ButtonCustom
                theme_white
                label="Delete"
                onClick={() => {
                  onDelete(wheelSlug, value.id);
                }}
              />
            </div>
          ))}
        </div>
        <AgencyFormCreator
          agencyID={wheelSlug}
          handleLoadWheel={handleLoadWheel}
          fetchCreatorService={fetchCreatorService}
          creatorData={creatorData}
        />
      </WheelCreatorSection>
    )
  );
};

export default WheelCreator;
