import React from "react";
import { connect } from "react-redux";
import { CreatorGenreContainerStyled } from "./styled";
import { adminService } from "apiService";
import { toast } from "react-toastify";
import { setReduxUserAuth } from "actions";
import { CreatorGenreFormSec3, CreatorServiceForm } from "forms/AdminFormSystem";
import { confirmAlert } from "react-confirm-alert";
import { Modal } from 'components'
import { change } from 'redux-form';

class CreatorGenreContainer extends React.Component {
  state = { isModal: false, genreOptions: [], haveService: false };

  componentDidMount() {
    this.fetchData();
    this.fetchGenresList()
    this.fetchProfile();
  }

  fetchData = async () => {
    const { creatorID } = this.props;
    let res = await adminService.GET_CATEDGORY_CREATOR_LIST(creatorID);

    if (res && res.success) {

      let result = res.data.filter((e) => {
        return e.service !== null
      })

      this.setState({
        data: res.data,
        haveService: result.length > 0 ? true : false,
        initialValues: {
          ...res.data,
        },
      });
    }
  };

  fetchProfile = async () => {
    const { creatorID } = this.props;
    let res = await adminService.GET_CREATOR_DETAIL(creatorID);
    if (res && res.success) {
      this.setState({
        creatorProfileData: res.data,
      });
    }
  };

  fetchGenresList = async () => {
    let res = await adminService.GET_GENRES_LIST();

    if (res && res.success) {
      this.setState({
        genreOptions: res.data.map((e, i) => ({
          label: e.label,
          value: e.slug,
        })),
      });
    }
  };

  handleRemoveGenre = async (slug) => {
    const { creatorID } = this.props;

    let res = await adminService.DELETE_CREATOR_GENRES(slug, creatorID);
    if (res && res.success) {
      toast.success("ลบสำเร็จ");
      this.fetchData();
    }
  };

  confirmModal = (e) => {
    const { creatorProfileData } = this.state

    confirmAlert({
      title: "Confirm to delete",
      message: `ต้องการลบ ${creatorProfileData.displayname} ออกจาก ${e}?`,
      buttons: [
        {
          label: "ยืนยัน",
          onClick: () => this.handleRemoveGenre(e),
        },
        {
          label: "ยกเลิก",
        },
      ],
    });
  };

  handdleSave = async (slug) => {
    const { creatorID } = this.props;
    let res = await adminService.GET_CREATOR_SERVICES_LIST(creatorID);

    if (res && res.success) {
      let data = [{ label: 'ไม่ระบุ', value: 'null' }]
      res.data.map((e) => (
        data.push({
          value: e.id,
          label: e.name
        })
      ))

      this.setState({
        isModal: true,
        slugValue: slug,
        renderModal: (
          <div>
            <CreatorServiceForm
              handleSave={this.handleCreateService}
              handleCancel={this.handleClickCloseModal}
              options={data}
            />
          </div>
        )
      })
    }
  };

  handleClickCloseModal = () => {
    this.props.resetDropDownGenres()
    this.setState({
      isModal: false,
      renderModal: false,
    });
  };


  handleCreateService = async () => {
    const {
      reduxForm2: { values, syncErrors },
    } = this.props;
    const {
      creatorID,
    } = this.props;
    const { slugValue } = this.state

    if (typeof syncErrors === "object") {
      toast.error("กรุณาเลือกข้อมูลด้วย", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      let params

      if (values.slug === 'null') {
        params = {
          id: creatorID,
        };
      } else {
        params = {
          id: creatorID,
          service_id: values.slug
        };
      }
      let res = await adminService.POST_CREATOR_GENRES_ADD(slugValue, params);

      if (res && res.success) {
        toast.success("บันทึกสำเร็จ");
        this.fetchData();
        this.setState({
          isModal: false,
          renderModal: false,
        })
      } else {
        toast.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
      }
    }
  };

  render() {
    const {
      initialValues,
      data,
      isModal,
      genreOptions,
      haveService,
      renderModal,
    } = this.state;
    return (
      <CreatorGenreContainerStyled>
        <CreatorGenreFormSec3
          data={data}
          genreOptions={genreOptions}
          initialValues={initialValues}
          handleRemoveGenre={this.confirmModal}
          handdleSave={this.handdleSave}
          haveService={haveService}
        />
        <Modal
          theme_modal_border
          isShow={isModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          {renderModal}
        </Modal>
      </CreatorGenreContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.CreatorGenreFormSec3,
  reduxForm2: state.form.CreatorServiceForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  resetDropDownGenres: () =>
    dispatch(change(`CreatorGenreFormSec3`, `slug`, null)),
  // resetDropDownService: () =>
  //   dispatch(change(`CreatorServiceForm`, `slug`, null)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatorGenreContainer);