import React from "react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { AgencyFormCreatorStyled } from "./styled";
import { adminService } from "apiService";
import { InputSuggestCreator2, ButtonCustom, BoxShow } from "components";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

const selector = formValueSelector("AgencyFormCreator");

class AgencyFormCreator extends React.Component {
  state = { creatorID: null };

  handleSelectedCreator = (e) => {
    this.setState({
      creatorID: e ? e.id : null,
    });
  };

  confirmModal = (e) => {
    confirmAlert({
      title: "Confirm to cancle",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handdleRemove(e),
        },
        {
          label: "No",
        },
      ],
    });
  };

  handdleSave = async () => {
    const { creatorID } = this.state;
    const { fetchCreatorService } = this.props;
    fetchCreatorService(creatorID)
    // const { creatorID } = this.state;
    // const { agencyID, handleLoadWheel, fetchCreatorService } = this.props;
    // let params = {
    //   id: creatorID,
    // };

    // if (creatorID) {
    //   let res = await adminService.POST_CREATOR_WHEEL(agencyID, params);
    //   if (res && res.success) {
    //     toast.success("บันทึกสำเร็จ");
    //     fetchCreatorService(creatorID)
    //     handleLoadWheel()
    //   } else {
    //     toast.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
    //   }
    // } else {
    //   toast.error("กรุณาเลือกข้อมูล Creator ก่อนบันทึก");
    // }
  };

  handdleRemove = async (e) => {
    const { agencyID } = this.props;

    let res = await adminService.DELETE_AGENCY_DELETE_CREATOR(agencyID, e);
    if (res && res.success) {
      toast.success("ลบสำเร็จ");
    } else {
      toast.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
    }
  };


  render() {
    const { dataCreator } = this.state;
    const { creatorData } = this.props
    return (
      <AgencyFormCreatorStyled>
        {dataCreator && (
          <div className="group_show">
            <BoxShow
              theme_standard
              data={dataCreator}
              handdleClick={this.confirmModal}
            />
          </div>
        )}
        <div className="box_label">
          <Field
            theme_standard
            name="user_id"
            component={InputSuggestCreator2}
            onChangeValue={this.handleSelectedCreator}
            dataReadySelect={creatorData && creatorData}
          />
        </div>
        <div className="button_layer">
          <ButtonCustom theme_yellow_2 label="ADD" onClick={this.handdleSave} />
        </div>
      </AgencyFormCreatorStyled>
    );
  }
}

const mapStateToProps = (state) => {
  const slug = selector(state, "slug");
  return {
    slug,
  };
};

const mapDispatchToProps = {
  change,
};

export default reduxForm({
  form: "AgencyFormCreator", // a unique identifier for this form
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(AgencyFormCreator));
