import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { CreatorServiceFormStyled } from './styled'
// import { iconImages } from 'assets'
import { VALIDATE } from "helpers"
import {
    InputDropdown
} from 'components'

class CreatorServiceForm extends React.Component {
    state = {

    }

    render() {
        const {
            handleSave,
            handleCancel,
            options,
        } = this.props
        return (
            <CreatorServiceFormStyled> 
                <div className='title'>
                    Creator Service
                </div>
                <Field
                    theme_full
                    name="slug"
                    options={options}
                    component={InputDropdown}
                    validate={VALIDATE.REQUIRE}
                />
                <div className='btn_layout'>
                    <button className='btn_add' onClick={handleSave}>
                        Add
                    </button>
                    <button className='btn_cancel' onClick={handleCancel}>
                        Cancel
                    </button>
                </div>
            </CreatorServiceFormStyled>
        )
    }
}

export default reduxForm({
    form: 'CreatorServiceForm',  // a unique identifier for this form
    enableReinitialize: true
})(CreatorServiceForm)
