import styled from "styled-components"
import { COLOR } from 'assets';
export const ChipStyle = styled.div`
/*===============================================
 Container 
===============================================*/

/*===============================================
Theme 
===============================================*/
    padding : 5px 10px;
    margin : 3px 5px;
    background-color : ${props => props.color ?? COLOR.YELLOW_1};
    color : ${props => props.titleColor ?? COLOR.BLACK_3};
    border-radius :5px;
    flex-wrap : nowrap;
    font-size : ${props => props.fontSize};
    display : flex; 
    justify-content : center;
    align-items : center;
    position: relative;
    :hover{
      background-color : ${COLOR.YELLOW_3};
    }
`