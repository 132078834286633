import apiService from "../apiService";

const apiPath = "api";

export const adminService = {
  // CREATOR
  GET_CREATOR_SEARCH_LIST: (keyword) => {
    return apiService.get(`${apiPath}/admins/me/creators?keyword=${keyword}`);
  },
  GET_CREATOR_LIST: () => {
    return apiService.get(`${apiPath}/admins/me/creators`);
  },
  GET_CREATOR_DETAIL: (id) => {
    return apiService.get(`${apiPath}/admins/me/creators/${id}`);
  },
  GET_ENROLL_CREATOR_LIST: () => {
    return apiService.get(`${apiPath}/admins/me/applicants`);
  },
  POST_CREATOR_ADD: (params) => {
    return apiService.post(`${apiPath}/admins/me/creators/`, params);
  },
  PATCH_CREATOR_UPDATE: (id, params) => {
    return apiService.patch(`${apiPath}/admins/me/creators/${id}`, params);
  },
  UPLOAD_CREATOR: (creatorID, params) => {
    return apiService.post_formdata(
      `${apiPath}/admins/me/creators/${creatorID}/posters`,
      params
    );
  },
  UPLOAD_CREATOR_SERVICE: (creatorID, ServiceID, params) => {
    return apiService.post_formdata(
      `${apiPath}/admins/me/creators/${creatorID}/services/${ServiceID}/posters`,
      params
    );
  },


  //USER
  GET_SEARCH_CREATOR_LIST: (keyword) => {
    return apiService.get(
      keyword
        ? `${apiPath}/admins/me/users?keyword=${keyword}`
        : `${apiPath}/admins/me/users`
    );
  },

  // GENRES
  GET_CATEDGORY_CREATOR_LIST: (id) => {
    return apiService.get(`${apiPath}/admins/me/creators/${id}/genres`);
  },
  GET_GENRES_LIST: () => {
    return apiService.get(`${apiPath}/admins/me/genres`);
  },
  GET_GENRES_DETAIL: (genreSlug) => {
    return apiService.get(`${apiPath}/admins/me/genres/${genreSlug}/creators`);
  },
  POST_CREATOR_GENRES_ADD: (genreSlug, params) => {
    return apiService.post(
      `${apiPath}/admins/me/genres/${genreSlug}/creators`,
      params
    );
  },
  POST_GENRES_ADD: (params) => {
    return apiService.post(`${apiPath}/admins/me/genres`, params);
  },
  GET_ADMIN_GENRES_DETAIL: (genre_slug) => {
    return apiService.get(`${apiPath}/admins/me/genres/${genre_slug}`);
  },
  PATCH_GENRE_UPDATE: (genre_slug, params) => {
    return apiService.patch(
      `${apiPath}/admins/me/genres/${genre_slug}`,
      params
    );
  },
  DELETE_CREATOR_GENRES: (genreSlug, creatorID) => {
    return apiService.delete(
      `${apiPath}/admins/me/genres/${genreSlug}/creators/${creatorID}`
    );
  },
  UPLOAD_GENRE: (genre_slug, params) => {
    return apiService.post_formdata(
      `${apiPath}/admins/me/genres/${genre_slug}/posters`,
      params
    );
  },

  // REFUND
  GET_REFUND_LIST: () => {
    return apiService.get(`${apiPath}/admins/me/refunds`);
  },
  PATCH_REFUND_CREATOR_UPDATE: (refundID, params) => {
    return apiService.patch(`${apiPath}/admins/me/refunds/${refundID}`, params);
  },

  // ACCOUNTING
  GET_REDEEMSLIST: () => {
    return apiService.get(`${apiPath}/admins/me/redeems`);
  },

  // ORDER
  GET_ORDER_LIST: (status, offset) => {
    return apiService.get(
      `${apiPath}/admins/me/orders?status=${status}&offset=${offset}`
    );
  },
  PATCH_ORDER_UPDATE: (params, order_id) => {
    return apiService.patch(`${apiPath}/admins/me/orders/${order_id}`, params);
  },

  // COUPON
  GET_COUPON_LIST: (offset) => {
    return apiService.get(`${apiPath}/admins/me/coupons?offset=${offset}`);
  },
  SEARCH_COUPON: (search) => {
    return apiService.get(`${apiPath}/admins/me/coupons?keyword=${search}`);
  },
  POST_COUPON_ADD: (params) => {
    return apiService.post(`${apiPath}/admins/me/coupons`, params);
  },
  PATCH_COUPON_EDIT: (params, coupon_code) => {
    return apiService.patch(
      `${apiPath}/admins/me/coupons/${coupon_code}`,
      params
    );
  },
  DELETE_COUPON_DELETE: (coupon_code) => {
    return apiService.delete(`${apiPath}/admins/me/coupons/${coupon_code}`);
  },

  // VOTE
  GET_VOTE_LIST: () => {
    return apiService.get(`${apiPath}/admins/me/candidates`);
  },

  // SERVICES CREATOR
  GET_CREATOR_SERVICES_LIST: (creatorID) => {
    return apiService.get(
      `${apiPath}/admins/me/creators/${creatorID}/services`
    );
  },
  POST_CREATOR_SERVICES_ADD: (creatorID, params) => {
    return apiService.post(
      `${apiPath}/admins/me/creators/${creatorID}/services`,
      params
    );
  },
  PATCH_CREATOR_SERVICES_EDIT: (creatorID, servicesID, params) => {
    return apiService.patch(
      `${apiPath}/admins/me/creators/${creatorID}/services/${servicesID}`,
      params
    );
  },
  DELETE_CREATOR_SERVICES_DELETE: (creatorID, servicesID) => {
    return apiService.delete(
      `${apiPath}/admins/me/creators/${creatorID}/services/${servicesID}`
    );
  },

  //  CATEGORIES
  GET_CATEGORIES_LIST: () => {
    return apiService.get(`${apiPath}/categories/groups`);
  },
  GET_CATEDGORY_LIST_ORIGIN: () => {
    return apiService.get(`${apiPath}/categories`);
  },
  GET_CATEDGORY_GROUP_LIST_SERVICE: (creator_id, service_id) => {
    return apiService.get(
      `${apiPath}/admins/me/creators/${creator_id}/services/${service_id}`
    );
  },

  // AGENCY
  GET_AGENCY_LIST: () => {
    return apiService.get(`${apiPath}/admins/me/agencies`);
  },
  GET_AGENCY_DETAIL: (agencyID) => {
    return apiService.get(`${apiPath}/admins/me/agencies/${agencyID}`);
  },
  GET_AGENCY_LIST_AGENT_BY_AGENCY_ID: (agencyID) => {
    return apiService.get(`${apiPath}/admins/me/agencies/${agencyID}/agents`);
  },
  GET_AGENCY_LIST_AGENT_BY_CREATOR_ID: (agencyID) => {
    return apiService.get(`${apiPath}/admins/me/agencies/${agencyID}/creators`);
  },
  POST_AGENCY_ADD: (params) => {
    return apiService.post(`${apiPath}/admins/me/agencies`, params);
  },
  PATCH_AGENCY_UPDATE: (agencyID, params) => {
    return apiService.patch(
      `${apiPath}/admins/me/agencies/${agencyID}`,
      params
    );
  },
  DELETE_AGENCY_DELETE: (agencyID) => {
    return apiService.delete(`${apiPath}/admins/me/agencies/${agencyID}`);
  },
  POST_AGENCY_ADD_AGENT: (agencyID, params) => {
    return apiService.post(
      `${apiPath}/admins/me/agencies/${agencyID}/agents`,
      params
    );
  },
  POST_AGENCY_ADD_CREATOR: (agencyID, params) => {
    return apiService.post(
      `${apiPath}/admins/me/agencies/${agencyID}/creators`,
      params
    );
  },
  DELETE_AGENCY_DELETE_AGENT: (agencyID, agentID) => {
    return apiService.delete(
      `${apiPath}/admins/me/agencies/${agencyID}/agents/${agentID}`
    );
  },
  DELETE_AGENCY_DELETE_CREATOR: (agencyID, creatorID) => {
    return apiService.delete(
      `${apiPath}/admins/me/agencies/${agencyID}/creators/${creatorID}`
    );
  },

  // AGENT
  GET_AGENT_SEARCH_LIST: (keyword) => {
    return apiService.get(`${apiPath}/admins/me/agents?keyword=${keyword}`);
  },
  GET_AGENT_LIST: () => {
    return apiService.get(`${apiPath}/admins/me/agents`);
  },
  GET_AGENT_DETAIL: (agentID) => {
    return apiService.get(`${apiPath}/admins/me/agents/${agentID}`);
  },
  POST_AGENT_ADD: (params) => {
    return apiService.post(`${apiPath}/admins/me/agents`, params);
  },
  PATCH_AGENT_UPDATE: (agentID, params) => {
    return apiService.patch(`${apiPath}/admins/me/agents/${agentID}`, params);
  },
  GET_WHEEL_LIST: () => {
    return apiService.get(`${apiPath}/admins/me/wheels`);
  },
  POST_WHEEL_LIST: (params) => {
    return apiService.post(`${apiPath}/admins/me/wheels`, params);
  },
  PATCH_WHEEL_UPDATE: (wheel_slug, params) => {
    return apiService.patch(
      `${apiPath}/admins/me/wheels/${wheel_slug}`,
      params
    );
  },
  POST_CREATOR_WHEEL: (wheel_slug, params) => {
    return apiService.post(
      `${apiPath}/admins/me/wheels/${wheel_slug}/creators`,
      params
    );
  },
  DELETE_CREATOR_WHEEL: (wheel_slug, creator_id) => {
    return apiService.delete(
      `${apiPath}/admins/me/wheels/${wheel_slug}/creators/${creator_id}`,
    );
  },
  GET_CREATOR_WHEEL: (wheel_slug) => {
    return apiService.get(
      `${apiPath}/admins/me/wheels/${wheel_slug}/creators/`
    );
  },
};
