import styled from 'styled-components';
import {
  COLOR,
  // FONT
} from 'assets';

export const CreatorGenreFormSec3Styled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  /* .item_init {
    .label {
      span {
        margin-left: 5px;
        color: ${COLOR.RED_1};
        &:hover {
          cursor: pointer;
        }
      }
    }
  } */
  .item_init {
    .label {
      background-color : ${COLOR.BLUE_5};
      padding : 3px 10px;
      margin : 0px 5px;
      color : ${COLOR.WHITE_1};
      border-radius : 5px;
    }
    span{
      margin-left : 10px;
      &:hover {
          cursor: pointer;
        }
    }
  }
  .box_label {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    @media only screen and (max-width:  600px) {
      display : block;
    }
    .label {
      min-width: 100px;
      margin-right: 10px;
    }
    .item_checkout {
      margin-right: 8px;
    }
  }
  .button_layer {
    text-align: right;
  }
  .genre_label{
    display : flex;
    .group_label {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .sub_label {
        font-size: 10px;
        &.minHeight {
          height: 15px;
        }
      }
    }
    .round {
      position: absolute;
      top: -8px;
      right: -8px;
      font-size: 10px;
      font-weight: bold;
      border: 2px solid;
      padding: 8px;
      height: 10px;
      width: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #0059b3;
      color: white;
      border-radius: 50%;
      cursor: pointer;
    }
    @media only screen and (max-width:  600px) {
      display : block;
    }
    
  }
`;