import React from "react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { CreatorGenreFormSec3Styled } from "./styled";
// import { adminService } from "apiService";
import { VALIDATE } from "helpers";
import { InputDropdown, Card, Chip } from "components";
import { connect } from "react-redux";

const selector = formValueSelector("CreatorGenreFormSec3");

class CreatorGenreFormSec3 extends React.Component {
  state = {};

  componentDidMount() {
    // this.fetchData();
  }

  renderDropDown = () => {
    const {
      data,
      genreOptions,
    } = this.props;

    if (!genreOptions) {
      return []
    } else {
      let tempdata = [...genreOptions]
      tempdata && tempdata.map((e, i) => (
        data && data.map((e1, j) => (
          e.label === e1.label ? tempdata.splice(i, 1) : ''
        ))
      ))
      return tempdata
    }
  }

  render() {
    const {
      data,
      handleSubmit,
      handdleSave,
      handleRemoveGenre,
      haveService,
    } = this.props;
    return (
      <CreatorGenreFormSec3Styled> 
        <form
          ref={(ref) => (this.myForm = ref)}
          onSubmit={handleSubmit(handdleSave)}
        >
          <Card title="Creator Genre">
            <div className="box_label">
              <div className="label">Add Genere</div>
              <Field
                theme_standard
                name="slug"
                options={this.renderDropDown()}
                component={InputDropdown}
                validate={VALIDATE.REQUIRE}
                onChange={(e) => {
                  handdleSave(e.target.value);
                }}
              />
            </div>
            <div className="genre_label">
              {data &&
                data.map((e, i) => (
                  <Chip key={i}>
                    <div className='group_label'>
                      <div>
                        {e.label}
                      </div>
                      <div className={`sub_label ${haveService ? 'minHeight' : ''}`}>
                        {e && e.service && e.service.name}
                      </div>
                    </div>
                    <div className='round' onClick={() => handleRemoveGenre(e.slug)}>
                      <div >
                        X
                      </div>
                    </div>
                  </Chip>
                ))}
            </div>
          </Card>
        </form>
      </CreatorGenreFormSec3Styled>
    );
  }
}

const mapStateToProps = (state) => {
  const slug = selector(state, "slug");
  return {
    slug,
  };
};

const mapDispatchToProps = {
  change,
};

export default reduxForm({
  form: "CreatorGenreFormSec3", // a unique identifier for this form
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(CreatorGenreFormSec3));